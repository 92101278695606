@import './variables.less';

@import "@fontsource-variable/nunito-sans";
@import "ng-zorro-antd/ng-zorro-antd.less";

span.highlight {
  text-decoration: underline;
  text-decoration-color: @primary-main;
  text-decoration-thickness: 0.15rem;
}


// >>>>>> Shared base settings <<<<<<

// unify transitions so that theme change looks good
.inner-layout * {
  transition:
    all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    padding 0ms,
    width 0ms,
    margin 0ms,
    background-color 0ms,
    background 0ms,
    border-color 0ms,
    box-shadow 0ms,
    color 0ms !important;
}

body {
  font-family: "Nunito Sans Variable", Arial, Helvetica, sans-serif;
  font-weight: normal;
  width: 100%;
  letter-spacing: 0.25px;
  font-size: @text-regular-fontsize;
  line-height: @text-lineheight;
  font-weight: 400;
  text-rendering: optimizelegibility;
  background-color: @bg-default;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

/* scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #cccccc #ffffff;
}

*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2;
}

*::-webkit-scrollbar-track:active {
  background-color: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #cccccc;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #919191;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #919191;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  display: block;
  padding: 0;
  cursor: default;
}

h1 {
  margin: @title-level1-margin;
  font-size: @title-level1-fontsize;
  line-height: @title-level1-lineheight;
  font-weight: @title-level1-fontweight;
}

h2 {
  margin: @title-level2-margin;
  font-size: @title-level2-fontsize;
  line-height: @title-level2-lineheight;
  font-weight: @title-level2-fontweight;
}

h3 {
  margin: @title-level3-margin;
  font-size: @title-level3-fontsize;
  line-height: @title-level3-lineheight;
  font-weight: @title-level3-fontweight;
}

h4 {
  margin: @title-level4-margin;
  font-size: @title-level4-fontsize;
  line-height: @title-level4-lineheight;
  font-weight: @title-level4-fontweight;
}

h5 {
  margin: @title-level5-margin;
  font-size: @title-level5-fontsize;
  line-height: @title-level5-lineheight;
  font-weight: @title-level5-fontweight;
}

h6 {
  margin: @title-level6-margin;
  font-size: @title-level6-fontsize;
  line-height: @title-level6-lineheight;
  font-weight: @title-level6-fontweight;
}

section {
  display: block;
  margin: @section-margin;
  padding: @section-padding;
}

p {
  margin: @text-margin;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:visited {
    text-decoration: none;
  }
}

*:focus,
*:focus-visible {
  outline: none;
}

// >>>>>> Ng Zorro Styling <<<<<<
// >>> CSS <<<

// >>> Variables <<<
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// >>> Base vars
@font-size-lg: @text-large-fontsize;
@font-size-base: @text-regular-fontsize;
@font-size-sm: @text-small-fontsize;
@heading-1-size: @title-level1-fontsize;
@heading-2-size: @title-level2-fontsize;
@heading-3-size: @title-level3-fontsize;
@heading-4-size: @title-level4-fontsize;
@heading-5-size: @title-level5-fontsize;
@line-height-base: 20px;
@font-variant-base: none;
@font-feature-settings-base: inherit;
@border-radius-base: @border-radius;

// >>> vertical padding for all form controls
@control-padding-horizontal: 22px;
